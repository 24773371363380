body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
:hover::-webkit-scrollbar-thumb {
  display: block;
}
::-webkit-scrollbar-thumb {
  background: #98989c;
  background: #d2d2d2;
  border-radius: 8px;
  display: none;
}
.mainInnerStyles::-webkit-scrollbar-track {
  background: transparent;
}
.mainInnerView {
  padding: 20px;
}

.listPaper {
  background: #fff;
  border: 1px solid #e8e8e8;
  padding: 20px;
}
h1 {
  color: #1c1b20;
  margin: 5px 0 25px;
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
a {
  color: #F8310E;
  text-decoration: none;
}
.orderStatus {
  padding: 4px 15px 3px;
  border-radius: 20px;
  color: #fff;
  opacity: 0.8;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -0.2px;
}
